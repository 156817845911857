import React from "react";
import Speech from "react-text-to-speech";


export default function UserMessage({ text }) {
    return (
        <div className="message-container">
            <div className="user-message">{text} <Speech text={text}/></div>
        </div>
    );
}