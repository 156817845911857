import axios from "axios"

const APIChatBox = {
    GetChatBotResponse: async message => {
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                const message_enc = encodeURI(message);
                if (message === "hi") {
                    resolve("你好，我是你的GenAI專家顧問，今天我可以為您提供什麼協助？");
                } else {
                    axios.get("https://us-central1-it114115-fyp-a03-2023.cloudfunctions.net/application/p23e7q86vsygdncg/"+message_enc)
                        .then((response) =>{
                            resolve(response.data);
                        })
                        .catch((error) => resolve('Hey... I am currently unable to answer your question! Mind if you can come back in a while?'));
                }
            }, 2000);
        });
    }
};

export default APIChatBox;