import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import JunctionBlackSiteListDetail from "../section-components/urban-dashboard-detail/junction-blacksite-list-detail";
const JunctionBlackSiteList = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Junction Blacksite List"  />
        <JunctionBlackSiteListDetail />
        <Footer />
    </div>
}

export default JunctionBlackSiteList

