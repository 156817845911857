import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class DailyAirQualityDetail extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return	<section className="service-two service-two__service-page service-two__about-page  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6">
			        {/*<div className="service-two__image">*/}
			        {/*  <img src={publicUrl+"assets/images/resources/about-page-moc-1.png"} alt={ imagealt } />*/}
			        {/*</div>/!* /.service-two__image *!/*/}
					  <div className='container-iframe'>
					      <iframe
					          className="responsive-iframe"
					          src="https://lookerstudio.google.com/embed/reporting/3ad29ef3-8c98-40f3-a34d-36b26d402846/page/lhFgD"
					      >
					      </iframe>
					  </div>
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-two__block">
			          <div className="block-title-two text-left">
			            <h3>HK Open Data on <br /> Daily Air Quality</h3>
			          </div>{/* /.block-title-two */}
			          <p>With the aid of Google Looker Studio, we can have an insight on how air quality changes everyday in Hong Kong.</p>
			          <ul className="list-unstyled video-one__list">
			            <li>
			              <i className="far fa-check" />
			              Density versus Time line plot
			            </li>
			            <li>
			              <i className="far fa-check" />
							Pollutant versus Time line plot
			            </li>
			            <li>
			              <i className="far fa-check" />
							Density versus District heat map
			            </li>
			          </ul>{/* /.list-unstyled video-one__list */}
			          {/*<Link to="/service" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></Link>*/}
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>



        }
}

export default DailyAirQualityDetail