import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import AnnualPollutantEmissionDetail from "../section-components/urban-dashboard-detail/annual-pollutant-emission-detail";
const AnnualPollutantEmission = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Annual Pollutant Emission"  />
        <AnnualPollutantEmissionDetail />
        <Footer />
    </div>
}

export default AnnualPollutantEmission

