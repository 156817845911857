import React, { useState, useEffect } from "react";
import Speech from "react-text-to-speech";

export default function BotMessage({ fetchMessage }) {
    const [isLoading, setLoading] = useState(true);
    const [message, setMessage] = useState("");

    useEffect(() => {
        async function loadMessage() {
            const msg = await fetchMessage();
            setLoading(false);
            setMessage(msg);
        }
        loadMessage();
    }, [fetchMessage]);

    const messagesExcluded = ['你好，我是你的GenAI專家顧問，今天我可以為您提供什麼協助？']
    console.log(message)
    console.log(messagesExcluded.includes(message))
    return (

        <div className="message-container">
            <div className="bot-message">
                {
                    isLoading ?
                        "Typing..." :
                        message
                }
                {
                    isLoading || messagesExcluded.includes(message)?
                        <></> :
                        <Speech text={message}/>
                }
            </div>
        </div>
    );
}