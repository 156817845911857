import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AnnualPollutantEmissionDetail extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return	<section className="service-two service-two__service-page service-two__about-page  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6">
			        {/*<div className="service-two__image">*/}
			        {/*  <img src={publicUrl+"assets/images/resources/about-page-moc-1.png"} alt={ imagealt } />*/}
			        {/*</div>/!* /.service-two__image *!/*/}
					  <div className='container-iframe'>
					      <iframe
					          className="responsive-iframe"
					          src="https://lookerstudio.google.com/embed/reporting/7ace4b2e-f4a5-4b8a-b9cb-c82774550905/page/kEUhD"
					      >
					      </iframe>
					  </div>
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-two__block">
			          <div className="block-title-two text-left">
			            <h3>HK Open Data on <br />Annual Pollutant Emission</h3>
			          </div>{/* /.block-title-two */}
			          <p>With the aid of Google Looker Studio, we can have an insight on annual pollutant emission in Hong Kong.</p>
			          <ul className="list-unstyled video-one__list">
			            <li>
			              <i className="far fa-check" />
			              Annual Emission (Tonnes) versus Time bar chart
			            </li>
			          </ul>{/* /.list-unstyled video-one__list */}
			          {/*<Link to="/service" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></Link>*/}
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>



        }
}

export default AnnualPollutantEmissionDetail