import React, { Component } from 'react';
class MarineDataWesternBufferDetail extends Component {

    render() {

    return	<section className="service-two service-two__service-page service-two__about-page  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6">
			        {/*<div className="service-two__image">*/}
			        {/*  <img src={publicUrl+"assets/images/resources/about-page-moc-1.png"} alt={ imagealt } />*/}
			        {/*</div>/!* /.service-two__image *!/*/}
					  <div className='container-iframe'>
					      <iframe
					          className="responsive-iframe"
					          src="https://lookerstudio.google.com/embed/reporting/f6f7719c-b63f-42b5-9df5-0fb3e6c98cb2/page/qgR"
					      >
					      </iframe>
					  </div>
			      </div>{/* /.col-lg-6 */}
			      <div className="col-lg-6">
			        <div className="service-two__block">
			          <div className="block-title-two text-left">
			            <h3>HK Open Data on <br />Marine Data - Western Buffer</h3>
			          </div>{/* /.block-title-two */}
			          <p>With the aid of Google Looker Studio, we can have an overview on water quality of Marine of Western Buffer in Hong Kong.</p>
			          <ul className="list-unstyled video-one__list">
			            {/*<li>*/}
			            {/*  <i className="far fa-check" />*/}
			            {/*  Average Traffic Accident versus Time line plot*/}
			            {/*</li>*/}
			            {/*<li>*/}
			            {/*  <i className="far fa-check" />*/}
						{/*	Aggregate Traffic Accident versus Time bar chart*/}
			            {/*</li>*/}
			          </ul>{/* /.list-unstyled video-one__list */}
			          {/*<Link to="/service" className="thm-btn">Read More <i className="fa fa-angle-double-right" /></Link>*/}
			          {/* /.thm-btn */}
			        </div>{/* /.service-two__block */}
			      </div>{/* /.col-lg-5 */}
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>



        }
}

export default MarineDataWesternBufferDetail