import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import DailyAirQualityDetail from "../section-components/urban-dashboard-detail/daily-air-quality-detail";
const DailyAirQuality = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Daily Air Quality"  />
        <DailyAirQualityDetail />
        <Footer />
    </div>
}

export default DailyAirQuality

