import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import MarineDataEasternBufferDetail
    from "../section-components/urban-dashboard-detail/marine-data-eastern-buffer-detail";
const MarineDataEasternBuffer = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Marine Data - Eastern Buffer"  />
        <MarineDataEasternBufferDetail />
        <Footer />
    </div>
}

export default MarineDataEasternBuffer

