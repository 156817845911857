import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import MarineDataToloHarbourDetail from "../section-components/urban-dashboard-detail/marine-data-tolo-harbour-detail";
const MarineDataToloHarbour = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Marine Data - Tolo Harbour"  />
        <MarineDataToloHarbourDetail />
        <Footer />
    </div>
}

export default MarineDataToloHarbour

