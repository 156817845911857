import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import DailyMeanAmountOfCloudDetail from "../section-components/urban-dashboard-detail/daily-mean-amount-of-cloud-detail";
const DailyMeanAmountOfCloud = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Daily Mean Amount Of Cloud"  />
        <DailyMeanAmountOfCloudDetail />
        <Footer />
    </div>
}

export default DailyMeanAmountOfCloud

