import React from 'react';
import Navbar from "../global-components/navbar-v2";
import PageHeader from '../global-components/page-header';
import Footer from '../global-components/footer-v2';
import TrafficAccidentStatisticsDetail from "../section-components/urban-dashboard-detail/traffic-accident-statistics-detail";

const TrafficAccidentStatistics = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Traffic Accident Statistics"  />
        <TrafficAccidentStatisticsDetail />
        <Footer />
    </div>
}

export default TrafficAccidentStatistics

