import React, { useState, useEffect, Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import IframeResizer from "iframe-resizer-react";

import BotMessage from "../chatbox/BotMessage";
import UserMessage from "../chatbox/UserMessage";
import Messages from "../chatbox/Messages";
import Input from "../chatbox/Input";
import apiChatbox from "../api-chatbox";
import Header from "../chatbox/Header";

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles({
    root: {
        paddingTop: '30px',

    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
});

function ContinuousSlider() {
    const classes = useStyles();
    const [temperature, setTemperature] = useState(0);
    const [tokenLimit, setTokenLimit] = useState(1000);
    const [topK, setTopK] = useState(40);
    const [topP, setTopP] = useState(0.8);

    const handleTemperatureChange = (event, newValue) => {
        setTemperature(newValue);
    };
    const handleTokenLimitChange = (event, newValue) => {
        setTokenLimit(newValue);
    };
    const handleTopKChange = (event, newValue) => {
        setTopK(newValue);
    };
    const handleTopPChange = (event, newValue) => {
        setTopP(newValue);
    };


    return (
        <div className={classes.root}>
            <Typography id="continuous-slider" gutterBottom>
                Temperature
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Slider
                        value={temperature}
                        onChange={handleTemperatureChange}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        min={0}
                        max={1}
                        step={0.05}
                    />
                </Grid>
            </Grid>
            <Typography id="continuous-slider" gutterBottom>
                Token Limit
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Slider
                        value={tokenLimit}
                        onChange={handleTokenLimitChange}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        min={500}
                        max={2000}
                        step={1}
                    />
                </Grid>
            </Grid>
            {/*<Typography id="continuous-slider" gutterBottom>*/}
            {/*    Top K*/}
            {/*</Typography>*/}
            {/*<Grid container spacing={2}>*/}
            {/*    <Grid item xs>*/}
            {/*        <Slider*/}
            {/*            value={topK}*/}
            {/*            onChange={handleTopKChange}*/}
            {/*            aria-labelledby="continuous-slider"*/}
            {/*            valueLabelDisplay="auto"*/}
            {/*            min={0}*/}
            {/*            max={40}*/}
            {/*            step={1}*/}
            {/*        />*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Typography id="continuous-slider" gutterBottom>
                Top P
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Slider
                        value={topP}
                        onChange={handleTopPChange}
                        aria-labelledby="continuous-slider"
                        valueLabelDisplay="auto"
                        min={0}
                        max={1}
                        step={0.05}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

function ChatBot() {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        async function loadWelcomeMessage() {
            setMessages([
                <BotMessage
                    key="0"
                    fetchMessage={async () => await apiChatbox.GetChatBotResponse("hi")}
                />
            ]);
        }
        loadWelcomeMessage();
    }, []);

    const send = async text => {
        const newMessages = messages.concat(
            <UserMessage key={messages.length + 1} text={text} />,
            <BotMessage
                key={messages.length + 2}
                fetchMessage={async () => await apiChatbox.GetChatBotResponse(text)}
            />,
        );
        setMessages(newMessages);
    };

    return (
        <div className="chatbot">
            <Header />
            <Messages messages={messages} />
            <Input onSend={send} />
        </div>
    );
}

class BannerV2 extends Component {
        constructor(props) {
            super(props);
        }
	 componentDidMount() {
	 	let publicUrl = process.env.PUBLIC_URL+'/'
        const cssUrl =   publicUrl + "assets/css/color-2.css";
        this.addStyle(cssUrl);

        const $ = window.$;
        if ($('.thm__owl-carousel').length) {
            $('.thm__owl-carousel').each(function () {
                var Self = $(this);
                var carouselOptions = Self.data('options');
                var carouselPrevSelector = Self.data('carousel-prev-btn');
                var carouselNextSelector = Self.data('carousel-next-btn');
                var thmCarousel = Self.owlCarousel(carouselOptions);
                if (carouselPrevSelector !== undefined) {
                    $(carouselPrevSelector).on('click', function () {
                        thmCarousel.trigger('prev.owl.carousel');
                        return false;
                    });
                }
                if (carouselNextSelector !== undefined) {
                    $(carouselNextSelector).on('click', function () {
                        thmCarousel.trigger('next.owl.carousel');
                        return false;
                    });
                }
            });
        }
    }


    addStyle = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "stylesheet";
        style.async = true;

        document.head.appendChild(style);


    }
    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="banner-two go-top">
			  <img src={publicUrl+"assets/images/shapes/banner-shape-1-1.png"} alt="Banner" className="banner-two__moc" />

            {/*<div className="banner-two__image-carousel owl-carousel owl-theme thm__owl-carousel" data-options="{&quot;loop&quot;: true, &quot;items&quot;: 1, &quot;margin&quot;: 0, &quot;dots&quot;: false, &quot;nav&quot;: false, &quot;animateOut&quot;: &quot;slideOutDown&quot;, &quot;animateIn&quot;: &quot;fadeIn&quot;, &quot;active&quot;: true, &quot;smartSpeed&quot;: 1000, &quot;autoplay&quot;: true, &quot;autoplayTimeout&quot;: 7000, &quot;autoplayHoverPause&quot;: false}">*/}
            {/*    <div className="item">*/}
			{/*      <img src={publicUrl+"assets/images/resources/banner-2-1.jpg"} alt="Banner" />*/}
			{/*    </div>/!* /.item *!/*/}
			{/*    <div className="item">*/}
			{/*      <img src={publicUrl+"assets/images/resources/banner-2-2.jpg"} alt="Banner" />*/}
			{/*    </div>/!* /.item *!/*/}
            {/*</div>/!* /.banner-two__image-carousel *!/*/}

			  <div className="container">
			    <div className="row">
                    <div className="col-xl-6 pt-30 pb-30">
                        <ChatBot />

                    </div>
                    <div className="col-xl-6 pt-30 pb-30">
                        <div className="banner-two__content">
                            <h3>
                                <span>Urban Sense</span>
                                <br/>
                                創造融合科技與城市發展的
                                <br/>
                                <em>智慧城市</em>
                            </h3>
                        </div>{/* /.banner-two__content */}
                        <ContinuousSlider />
                    </div>
			    </div>{/* /.row */}
			  </div>{/* /.container */}
			</section>

        }
}

export default BannerV2